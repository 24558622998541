import React, { ChangeEvent, useState, SetStateAction, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import { LuPen, LuPlus, LuTrash } from "react-icons/lu";
import ITemplateService, { ISharepoint, IYoutube } from "../../Services/TemplateService/ITemplateService";
import IAgentService from "../../Services/AgentServices/IAgentService";
import { toastError } from "../Toast/Toast";
import DataTable, { TableColumn } from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import AgentService from "../../Services/AgentServices/AgentService";
import { useSearchParams } from "react-router-dom";
import { ThreeDotLoader } from "../Loader/Loader";
interface Props {
    SetAssistantData: React.Dispatch<SetStateAction<ITemplateService | IAgentService>>
    AssistantData: IAgentService
    DisableSubmitButton: any
}

const Sharepoint: React.FC<Props> = ({ SetAssistantData, AssistantData, DisableSubmitButton }) => {
    const [showFormYT, setShowFormYT] = useState(false)
    const [showLoader, setShowLoader] = useState(false);
    const [editingIndex, setEditingIndex] = useState<number | null>(null)
    const [searchParams] = useSearchParams();
    const assId = searchParams.get('assistantId')
    const selectedAssistantId = useRef(assId ? assId : "");
    const [createFormYT, setCreateFormYT] = useState<ISharepoint>({
        name: "",
        siteName: "",
        folderPath: "",
        ClientId: "",
        tenantId: "",
        clientSecret: "",
    })
    const handleCancel = () => {
        setShowFormYT(false)
        setEditingIndex(null)
    }
    const handleChangeForm = (e: ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name
        let value = e.target.value
        setCreateFormYT((preVal) => ({ ...preVal, [name]: value }))
    }
    const handleShowYT = () => {
        setCreateFormYT({
            name: "",
            siteName: "",
            folderPath: ""
        })
        setShowFormYT(true);
        setEditingIndex(null)
    };
    const validateForm = (dataArr: IYoutube[]) => {
        let flag = true
        // const regexYTUrl = /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$/
        // if (!createFormYT.name) {
        //     toastError("Please Enter Name");
        //     flag = false
        // }
        // if (!createFormYT.youtubeLink) {
        //     toastError("Please Enter Youtube link");
        //     flag = false
        // }

        // if (createFormYT.youtubeLink && !regexYTUrl.test(createFormYT.youtubeLink.trim())) {
        //     toastError("Please Enter Valid Youtube link");
        //     flag = false
        // }


        // if (!createFormYT.videoApikey) {
        //     toastError("Please Enter Video ApiKey");
        //     flag = false
        // }

        // const NameUnique = new Set(dataArr?.map(v => v.name));
        // if (NameUnique.size !== dataArr?.length) {
        //     toastError("Youtube name already exists");
        //     flag = false
        // }

        return flag
    };
    const handleCreate = (action: string) => {
        SetAssistantData((preVal) => {
            let sharepoint = preVal.sharepoint
            if (action == "edit") {
                sharepoint = preVal.sharepoint?.filter((item, index) => index !== editingIndex)
            }
            sharepoint = [...(sharepoint || []), createFormYT]
            if (validateForm(sharepoint)) {
                setShowFormYT(false)
                DisableSubmitButton.current = false
                return {
                    ...preVal,
                    sharepoint: sharepoint
                }
            } else {
                return {
                    ...preVal,
                    sharepoint: preVal.sharepoint
                }

            }
        })
        setEditingIndex(null)
    }
    const handleEditYoutube = (row: ISharepoint, index: number) => {
        setCreateFormYT(row)
        setShowFormYT(true);
        setEditingIndex(index)
    }
    const deleteYoutube = (row: ISharepoint) => {
        var deleteItem
        const youtubeUpdatedArr = AssistantData.sharepoint?.filter(
            (item: any) => item.name !== row.name
        );
        DisableSubmitButton.current = false
        SetAssistantData((prevData: any) => ({
            ...prevData,
            sharepoint: youtubeUpdatedArr,
        }));
        // setShowLoader(true)
        // if (selectedAssistantId.current && row.youtubeLink) {
        //     AgentService.DeleteYoutubeLink(row.youtubeLink, selectedAssistantId.current).then(res => {
        //         SetAssistantData((prevData: any) => ({
        //             ...prevData,
        //             youtube: youtubeUpdatedArr,
        //         }));
        //     }).catch(err => {
        //         SetAssistantData((prevData: any) => ({
        //             ...prevData,
        //             youtube: prevData.youtube,
        //         }));
        //         toastError(err?.response?.data?.response)
        //     }).finally(() => {
        //         setShowLoader(false)
        //     })
        // }

    }
    const handleRemoveYoutube = (row: IYoutube, index: number) => {
        confirmAlert({
            title: "Confirm Delete",
            message: "Are you sure you want to delete this sharepoint?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        deleteYoutube(row)
                    },
                },
                {
                    label: "No",
                    onClick: () => { },
                },
            ],
        });
    }
    const YTTableColumn: TableColumn<any>[] = [
        {
            name: "Name",
            selector: (row: any) => row.name,
            sortable: true,
            width: "200px",
            cell: (row: any) => (
                <div
                    style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={row.name}
                >
                    {row.name.length > 50
                        ? `${row.name.substring(0, 50)}...`
                        : row.name}
                </div>
            ),
        },
        {
            name: "Site Name",
            selector: (row: any) => row.siteName,
            sortable: true,
            width: "200px",
            cell: (row: any) => (
                <div
                    style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={row.siteName}
                >
                    {row.siteName.length > 25
                        ? `${row.siteName.substring(0, 25)}...`
                        : row.siteName}
                </div>
            ),
        },
        {
            name: "Folder path",
            selector: (row: any) => row.folderPath,
            sortable: true,
            width: "200px",
            cell: (row: any) => (
                <div
                    style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={row.folderPath}
                >
                    {row.folderPath.length > 25
                        ? `${row.folderPath.substring(0, 25)}...`
                        : row.folderPath}
                </div>
            ),
        },
        {
            name: "Client ID",
            selector: (row: any) => row.ClientId,
            sortable: true,
            width: "200px",
            cell: (row: any) => (
                <div
                    style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={row.ClientId}
                >
                    {row.ClientId.length > 25
                        ? `${row.ClientId.substring(0, 25)}...`
                        : row.ClientId}
                </div>
            ),
        },
        {
            name: "Tenant ID",
            selector: (row: any) => row.tenantId,
            sortable: true,
            width: "200px",
            cell: (row: any) => (
                <div
                    style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={row.tenantId}
                >
                    {row.tenantId.length > 25
                        ? `${row.tenantId.substring(0, 25)}...`
                        : row.tenantId}
                </div>
            ),
        },
        {
            name: "Client Secret",
            selector: (row: any) => row.clientSecret,
            sortable: true,
            width: "200px",
            cell: (row: any) => (
                <div
                    style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={row.clientSecret}
                >
                    {row.clientSecret.length > 25
                        ? `${row.clientSecret.substring(0, 25)}...`
                        : row.clientSecret}
                </div>
            ),
        },
        {
            name: " ",
            sortable: false,
            button: true,
            right: true,
            cell: (row: any, rowIndex: any) => (
                <>
                    <div className="gridIcons" data-tag="allowRowEvents">
                        <>
                            <>
                                <button
                                    type="button"
                                    className="btn plainBtn deleteBtn"
                                    title="Edit"
                                    onClick={() => handleEditYoutube(row, rowIndex)}
                                >
                                    <LuPen />
                                </button>
                            </>
                        </>
                    </div>
                    <div className="gridIcons" data-tag="allowRowEvents">
                        <>
                            <>
                                <button
                                    type="button"
                                    className="btn plainBtn deleteBtn"
                                    title="Delete"
                                    onClick={() => handleRemoveYoutube(row, rowIndex)}
                                >
                                    <LuTrash />
                                </button>
                            </>
                        </>
                    </div>
                </>
            ),
        },
    ]
    return (<>
        <div className="commonDiv">
            <div className="titleBar d-flex align-items-center">
                <h3> SharePoint <span>({AssistantData.sharepoint?.length || 0})</span></h3>
                <div className="ms-auto">
                    <div className="d-flex gap-2">
                        <button
                            className="btn plainBtn ms-auto"
                            type="button"
                            onClick={() => handleShowYT()}

                        >
                            <LuPlus />
                        </button>
                    </div>
                </div>
            </div>
            <div className="dataTable">
                {AssistantData?.sharepoint && (
                    <DataTable
                        noHeader={true}
                        columns={YTTableColumn}
                        data={
                            AssistantData?.sharepoint ? AssistantData?.sharepoint : []
                        }
                        // pagination
                        // paginationServer
                        pagination={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 15, 20]}
                    />
                )}
            </div>
            {showFormYT &&
                <div className="popup-container create-template-popup">
                    <div className="shadeClose" onClick={() => handleCancel()}></div>
                    <div className="popup-inner">
                        <div className="inner-design">
                            <div className="popup-header">
                                <h3>SharePoint</h3>
                                <button type="button" onClick={() => handleCancel()} className="close"><FaTimes /></button>
                            </div>

                            <div className="popup-content">
                                <div className="row">
                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Name <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Enter the name"
                                            className="form-control"
                                            onChange={(event) => handleChangeForm(event)}
                                            value={createFormYT.name}
                                        />
                                    </div>
                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Site Name<span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="siteName"
                                            placeholder="Enter the site name"
                                            className="form-control"
                                            onChange={(event) => handleChangeForm(event)}
                                            value={createFormYT.siteName}
                                        />
                                    </div>

                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Folder Path <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="folderPath"
                                            className="form-control"
                                            placeholder="Enter the folder path"
                                            onChange={(event) => handleChangeForm(event)}
                                            value={createFormYT.folderPath}
                                        />
                                    </div>
                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Client ID <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="ClientId"
                                            className="form-control"
                                            placeholder="Enter the client ID"
                                            onChange={(event) => handleChangeForm(event)}
                                            value={createFormYT.ClientId}
                                        />
                                    </div>
                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Tenant ID<span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="tenantId"
                                            className="form-control"
                                            placeholder="Enter the tenant ID"
                                            onChange={(event) => handleChangeForm(event)}
                                            value={createFormYT.tenantId}
                                        />
                                    </div>
                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Client Secret <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="clientSecret"
                                            placeholder="Enter the client secret"
                                            className="form-control"
                                            onChange={(event) => handleChangeForm(event)}
                                            value={createFormYT.clientSecret}
                                        />
                                    </div>






                                </div>



                                <div className="d-flex gap-2 mt-2">
                                    <button type="button" className="btn btn-secondary " onClick={() => handleCancel()}>Cancel</button>
                                    <button className="btn btn-primary ms-auto" onClick={() => handleCreate(editingIndex != null ? "edit" : "create")}>Done</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
        <ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
    </>)
}

export default Sharepoint