import React, { ChangeEvent, ReactEventHandler, useEffect, useState, SetStateAction } from "react";
import ITemplateService, { IGithub } from "../../Services/TemplateService/ITemplateService";
import { FaTimes } from "react-icons/fa";
import IAgentService from "../../Services/AgentServices/IAgentService";
import { toastError } from "../Toast/Toast";
import { LuPen, LuPlus, LuTrash } from "react-icons/lu";
import DataTable, { TableColumn } from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
interface Props {
    SetAssistantData: React.Dispatch<SetStateAction<ITemplateService | IAgentService>>
    AssistantData: IAgentService
    DisableSubmitButton: any
}


const GitHub: React.FC<Props> = ({ SetAssistantData, AssistantData, DisableSubmitButton }) => {
    const [showFormGH, setShowFormGH] = useState<boolean>(false);
    const [editingIndex, setEditingIndex] = useState<null | number>(null);
    const [createFormGH, setCreateFormGH] = useState<IGithub>({
        repoName: "",
        branch: "",
        accessToken: "",
        repoOwner: ""
    })
    useEffect(() => {

    }, [])

    const handleChangeForm = (e: ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name
        let value = e.target.value
        setCreateFormGH((preVal) => ({ ...preVal, [name]: value }))
    }
    const validateForm = () => {
        let flag = true

        if (!createFormGH.repoName) {
            toastError("Please Enter  Repo Name");
            flag = false
        }

        if (!createFormGH.accessToken) {
            toastError("Please Enter Access Token");
            flag = false
        }
        if (!createFormGH.branch) {
            toastError("Please Enter Branch");
            flag = false
        }
        if (!createFormGH.repoOwner) {
            toastError("Please Enter Repo Owner");
            flag = false
        }
        const NameUnique = new Set(AssistantData.github?.map(v => v.repoName));
        if (NameUnique.size !== AssistantData.github?.length) {
            toastError("Connection name already exists");
            flag = false
        }

        return flag
    };

    const handleCreate = (mode: string) => {
        SetAssistantData((preVal) => {
            let github = preVal.github
            if (mode == "edit") {
                github = preVal.github?.filter((item, index) => index !== editingIndex)
            }
            github = [...(github || []), createFormGH]
            if (validateForm()) {
                setShowFormGH(false)
                DisableSubmitButton.current = false
                return {
                    ...preVal,
                    github: github
                }
            } else {
                return {
                    ...preVal,
                    github: preVal.github
                }

            }
        })
        setEditingIndex(null)
    }
    const handleRemoveGitHub = (item: any) => {
        confirmAlert({
            title: "Confirm Delete",
            message: "Are you sure you want to delete this GitHub?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        // Remove item from createTemplateData.DatabaseConnections
                        const github = AssistantData.github?.filter(
                            (connection: any) => connection.repoName !== item.repoName
                        );
                        DisableSubmitButton.current = false
                        SetAssistantData((prevData: any) => ({
                            ...prevData,
                            github: github,
                        }));
                    },
                },
                {
                    label: "No",
                    onClick: () => { },
                },
            ],
        });
    };
    const handleCancel = () => {
        setShowFormGH(false)
        setEditingIndex(null)
    }
    const handleCreateGH = () => {
        setShowFormGH(true);
        setEditingIndex(null)
    };
    const handleEditGitHub = (item: any, index: number) => {
        setCreateFormGH(item)
        setShowFormGH(true);
        setEditingIndex(index)
    };

    const GHTableColumn: TableColumn<any>[] = [
        {
            name: "Name",
            selector: (row: any) => row.repoName,
            sortable: true,
            width: "250px",
            cell: (row: any) => (
                <div
                    style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={row.repoName}
                >
                    {row.repoName.length > 50
                        ? `${row.repoName.substring(0, 50)}...`
                        : row.repoName}
                </div>
            ),
        },
        {
            name: "Access Token",
            selector: (row: any) => row.accessToken,
            sortable: true,
            width: "175px",
            cell: (row: any) => (
                <div
                    style={{
                        maxWidth: "100px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={row.accessToken}
                >
                    {row.accessToken.length > 10
                        ? `${row.accessToken.substring(0, 10)}...`
                        : row.accessToken}
                </div>
            ),
        },
        {
            name: "Branch",
            selector: (row: any) => row.branch,
            sortable: true,
            cell: (row: any) => (
                <div
                    style={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={row.branch}
                >
                    {row.branch.length > 50
                        ? `${row.branch.substring(0, 50)}...`
                        : row.branch}
                </div>
            ),
        },
        {
            name: "Repo Owner",
            selector: (row: any) => row.repoOwner,
            sortable: true,
            width: "200px",
        },
        {
            name: " ",
            sortable: false,
            button: true,
            right: true,
            cell: (row: any, rowIndex: any) => (
                <>
                    <div className="gridIcons" data-tag="allowRowEvents">
                        <>
                            <>
                                <button
                                    type="button"
                                    className="btn plainBtn deleteBtn"
                                    title="Edit"
                                    onClick={() => handleEditGitHub(row, rowIndex)}
                                >
                                    <LuPen />
                                </button>
                            </>
                        </>
                    </div>
                    <div className="gridIcons" data-tag="allowRowEvents">
                        <>
                            <>
                                <button
                                    type="button"
                                    className="btn plainBtn deleteBtn"
                                    title="Delete"
                                    onClick={() => handleRemoveGitHub(row)}
                                >
                                    <LuTrash />
                                </button>
                            </>
                        </>
                    </div>
                </>
            ),
        },
        // {
        //     name: " ",
        //     sortable: false,
        //     button: true,
        //     right: true,
        //     cell: (row: any, rowIndex: any) => (
        //         <div className="gridIcons" data-tag="allowRowEvents">
        //             <>
        //                 <>
        //                     <button
        //                         type="button"
        //                         className="btn plainBtn deleteBtn"
        //                         title="Delete"
        //                         onClick={() => handleRemoveGitHub(row)}
        //                     >
        //                         <LuTrash />
        //                     </button>
        //                 </>
        //             </>
        //         </div>
        //     ),
        // },
    ];



    return (
        <>     <div className="commonDiv">
            <div className="titleBar d-flex align-items-center">
                <h3> GitHub</h3>
                <div className="ms-auto">
                    <div className="d-flex gap-2">
                        <button
                            className="btn plainBtn ms-auto"
                            type="button"
                            onClick={handleCreateGH}
                            disabled={
                                AssistantData?.github?.length &&
                                    AssistantData?.github?.length > 0
                                    ? true
                                    : false
                            }
                        >
                            <LuPlus />
                        </button>
                    </div>
                </div>
            </div>
            <div className="dataTable">
                {AssistantData?.github && (
                    <DataTable
                        noHeader={true}
                        columns={GHTableColumn}
                        data={
                            AssistantData?.github ? AssistantData?.github : []
                        }
                        // pagination
                        // paginationServer
                        pagination={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 15, 20]}
                    />
                )}
            </div>

            {showFormGH &&
                <div className="popup-container create-template-popup">
                    <div className="shadeClose" onClick={() => handleCancel()}></div>
                    <div className="popup-inner">
                        <div className="inner-design">
                            <div className="popup-header">
                                <h3>New Repo</h3>
                                <button type="button" onClick={() => handleCancel()} className="close"><FaTimes /></button>
                            </div>

                            <div className="popup-content">
                                <div className="row">
                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Repo Name <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="repoName"
                                            className="form-control"
                                            placeholder="Enter the repo name"
                                            onChange={(event) => handleChangeForm(event)}
                                            value={createFormGH.repoName}
                                        />
                                    </div>

                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Branch <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="branch"
                                            className="form-control"
                                            placeholder="Enter the repo branch"
                                            onChange={(event) => handleChangeForm(event)}
                                            value={createFormGH.branch}
                                        />
                                    </div>

                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Access Token <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="accessToken"
                                            className="form-control"
                                            placeholder="Enter the access token"
                                            onChange={(event) => handleChangeForm(event)}
                                            value={createFormGH.accessToken}
                                        />
                                    </div>
                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Repo Owner <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="repoOwner"
                                            className="form-control"
                                            placeholder="Enter the repo owner"
                                            onChange={(event) => handleChangeForm(event)}
                                            value={createFormGH.repoOwner}
                                        />
                                    </div>


                                </div>



                                <div className="d-flex gap-2 mt-2">
                                    <button type="button" className="btn btn-secondary " onClick={() => handleCancel()}>Cancel</button>
                                    <button className="btn btn-primary ms-auto" onClick={() => handleCreate(editingIndex != null ? "edit" : "create")}>Done</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
        </>)

}

export default GitHub