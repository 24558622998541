import React, { ChangeEvent, useState, SetStateAction, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import { LuPen, LuPlus, LuTrash } from "react-icons/lu";
import ITemplateService, { IYoutube } from "../../Services/TemplateService/ITemplateService";
import IAgentService from "../../Services/AgentServices/IAgentService";
import { toastError } from "../Toast/Toast";
import DataTable, { TableColumn } from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import AgentService from "../../Services/AgentServices/AgentService";
import { useSearchParams } from "react-router-dom";
import { ThreeDotLoader } from "../Loader/Loader";
interface Props {
    SetAssistantData: React.Dispatch<SetStateAction<ITemplateService | IAgentService>>
    AssistantData: IAgentService
    DisableSubmitButton: any
}

const Youtube: React.FC<Props> = ({ SetAssistantData, AssistantData, DisableSubmitButton }) => {
    const [showFormYT, setShowFormYT] = useState(false)
    const [showLoader, setShowLoader] = useState(false);
    const [editingIndex, setEditingIndex] = useState<number | null>(null)
    const [searchParams] = useSearchParams();
    const assId = searchParams.get('assistantId')
    const selectedAssistantId = useRef(assId ? assId : "");
    const [createFormYT, setCreateFormYT] = useState<IYoutube>({
        name: "",
        videoApikey: "",
        youtubeLink: ""
    })
    const handleCancel = () => {
        setShowFormYT(false)
        setEditingIndex(null)
    }
    const handleChangeForm = (e: ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name
        let value = e.target.value
        setCreateFormYT((preVal) => ({ ...preVal, [name]: value }))
    }
    const handleShowYT = () => {
        setCreateFormYT({
            name: "",
            videoApikey: "",
            youtubeLink: ""
        })
        setShowFormYT(true);
        setEditingIndex(null)
    };
    const validateForm = (dataArr: IYoutube[]) => {
        let flag = true
        const regexYTUrl = /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$/
        if (!createFormYT.name) {
            toastError("Please Enter Name");
            flag = false
        }
        if (!createFormYT.youtubeLink) {
            toastError("Please Enter Youtube link");
            flag = false
        }

        if (createFormYT.youtubeLink && !regexYTUrl.test(createFormYT.youtubeLink.trim())) {
            toastError("Please Enter Valid Youtube link");
            flag = false
        }


        if (!createFormYT.videoApikey) {
            toastError("Please Enter Video ApiKey");
            flag = false
        }

        const NameUnique = new Set(dataArr?.map(v => v.name));
        if (NameUnique.size !== dataArr?.length) {
            toastError("Youtube name already exists");
            flag = false
        }

        return flag
    };
    const handleCreate = (action: string) => {
        SetAssistantData((preVal) => {
            let youtube = preVal.youtube
            if (action == "edit") {
                youtube = preVal.youtube?.filter((item, index) => index !== editingIndex)
            }
            youtube = [...(youtube || []), createFormYT]
            if (validateForm(youtube)) {
                setShowFormYT(false)
                DisableSubmitButton.current = false
                return {
                    ...preVal,
                    youtube: youtube
                }
            } else {
                return {
                    ...preVal,
                    youtube: preVal.youtube
                }

            }
        })
        setEditingIndex(null)
    }
    const handleEditYoutube = (row: IYoutube, index: number) => {
        setCreateFormYT(row)
        setShowFormYT(true);
        setEditingIndex(index)
    }
    const deleteYoutube = (row: IYoutube) => {
        var deleteItem
        const youtubeUpdatedArr = AssistantData.youtube?.filter(
            (item: any) => item.name !== row.name
        );
        DisableSubmitButton.current = false
        setShowLoader(true)
        if (selectedAssistantId.current && row.youtubeLink) {
            AgentService.DeleteYoutubeLink(row.youtubeLink, selectedAssistantId.current).then(res => {
                SetAssistantData((prevData: any) => ({
                    ...prevData,
                    youtube: youtubeUpdatedArr,
                }));
            }).catch(err => {
                SetAssistantData((prevData: any) => ({
                    ...prevData,
                    youtube: prevData.youtube,
                }));
                toastError(err?.response?.data?.response)
            }).finally(() => {
                setShowLoader(false)
            })
        }

    }
    const handleRemoveYoutube = (row: IYoutube, index: number) => {
        confirmAlert({
            title: "Confirm Delete",
            message: "Are you sure you want to delete this youtube?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        deleteYoutube(row)
                    },
                },
                {
                    label: "No",
                    onClick: () => { },
                },
            ],
        });
    }
    const YTTableColumn: TableColumn<any>[] = [
        {
            name: "Name",
            selector: (row: any) => row.name,
            sortable: true,
            width: "250px",
            cell: (row: any) => (
                <div
                    style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={row.name}
                >
                    {row.name.length > 50
                        ? `${row.name.substring(0, 50)}...`
                        : row.name}
                </div>
            ),
        },
        {
            name: "Youtube link",
            selector: (row: any) => row.youtubeLink,
            sortable: true,
            width: "250px",
            cell: (row: any) => (
                <div
                    style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={row.youtubeLink}
                >
                    {row.youtubeLink.length > 25
                        ? `${row.youtubeLink.substring(0, 25)}...`
                        : row.youtubeLink}
                </div>
            ),
        },
        {
            name: "Video Apikey",
            selector: (row: any) => row.videoApikey,
            sortable: true,
            width: "250px",
            cell: (row: any) => (
                <div
                    style={{
                        maxWidth: "250px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={row.videoApikey}
                >
                    {row.videoApikey.length > 25
                        ? `${row.videoApikey.substring(0, 25)}...`
                        : row.videoApikey}
                </div>
            ),
        },
        {
            name: " ",
            sortable: false,
            button: true,
            right: true,
            cell: (row: any, rowIndex: any) => (
                <>
                    <div className="gridIcons" data-tag="allowRowEvents">
                        <>
                            <>
                                <button
                                    type="button"
                                    className="btn plainBtn deleteBtn"
                                    title="Edit"
                                    onClick={() => handleEditYoutube(row, rowIndex)}
                                >
                                    <LuPen />
                                </button>
                            </>
                        </>
                    </div>
                    <div className="gridIcons" data-tag="allowRowEvents">
                        <>
                            <>
                                <button
                                    type="button"
                                    className="btn plainBtn deleteBtn"
                                    title="Delete"
                                    onClick={() => handleRemoveYoutube(row, rowIndex)}
                                >
                                    <LuTrash />
                                </button>
                            </>
                        </>
                    </div>
                </>
            ),
        },
    ]
    return (<>
        <div className="commonDiv">
            <div className="titleBar d-flex align-items-center">
                <h3> Youtube <span>({AssistantData.youtube?.length})</span></h3>
                <div className="ms-auto">
                    <div className="d-flex gap-2">
                        <button
                            className="btn plainBtn ms-auto"
                            type="button"
                            onClick={() => handleShowYT()}

                        >
                            <LuPlus />
                        </button>
                    </div>
                </div>
            </div>
            <div className="dataTable">
                {AssistantData?.youtube && (
                    <DataTable
                        noHeader={true}
                        columns={YTTableColumn}
                        data={
                            AssistantData?.youtube ? AssistantData?.youtube : []
                        }
                        // pagination
                        // paginationServer
                        pagination={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 15, 20]}
                    />
                )}
            </div>
            {showFormYT &&
                <div className="popup-container create-template-popup">
                    <div className="shadeClose" onClick={() => handleCancel()}></div>
                    <div className="popup-inner">
                        <div className="inner-design">
                            <div className="popup-header">
                                <h3>New Youtube</h3>
                                <button type="button" onClick={() => handleCancel()} className="close"><FaTimes /></button>
                            </div>

                            <div className="popup-content">
                                <div className="row">
                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Name <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            placeholder="Enter the name"
                                            onChange={(event) => handleChangeForm(event)}
                                            value={createFormYT.name}
                                        />
                                    </div>
                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Video Apikey <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="videoApikey"
                                            className="form-control"
                                            placeholder="Enter the video Apikey"

                                            onChange={(event) => handleChangeForm(event)}
                                            value={createFormYT.videoApikey}
                                        />
                                    </div>

                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Youtube Link <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="youtubeLink"
                                            placeholder="Enter the youtube link"
                                            className="form-control"
                                            onChange={(event) => handleChangeForm(event)}
                                            value={createFormYT.youtubeLink}
                                        />
                                    </div>






                                </div>



                                <div className="d-flex gap-2 mt-2">
                                    <button type="button" className="btn btn-secondary " onClick={() => handleCancel()}>Cancel</button>
                                    <button className="btn btn-primary ms-auto" onClick={() => handleCreate(editingIndex != null ? "edit" : "create")}>Done</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
        <ThreeDotLoader visibility={showLoader}></ThreeDotLoader>
    </>)
}

export default Youtube