import React from "react";
import "./Integrations.scss";
import IAgentService from "../../../Services/AgentServices/IAgentService";

interface Props {
  AgentInfo: IAgentService;
}

const Integrations = ({}) => {
  return (
    <>
      <div className="integrationsHolder ">
        <h3 className="mb-4">Integrations</h3>
        <ul className="row">
          <li className="col-12 col-md-3">
            <a>
              <div className="coming-soon">Coming Soon</div>
              <span>
                <img
                  src={require("../../../common/images/whatsapp.png")}
                  alt=""
                />
              </span>
              <p>WhatsApp</p>
            </a>
          </li>
          <li className="col-12 col-md-3">
            <a>
              <div className="coming-soon">Coming Soon</div>
              <span>
                <img
                  src={require("../../../common/images/salesforce.png")}
                  alt=""
                />
              </span>
              <p>Salesforce</p>
            </a>
          </li>
          <li className="col-12 col-md-3">
            <a>
              <div className="coming-soon">Coming Soon</div>
              <span>
                <img
                  src={require("../../../common/images/Quickbooks.png")}
                  alt=""
                />
              </span>
              <p>QuickBooks</p>
            </a>
          </li>
          <li className="col-12 col-md-3">
            <a>
              <div className="coming-soon">Coming Soon</div>
              <span>
                <img src={require("../../../common/images/Keka.png")} alt="" />
              </span>
              <p>Keka</p>
            </a>
          </li>
          <li className="col-12 col-md-3">
            <a>
              <div className="coming-soon">Coming Soon</div>
              <span>
                <img
                  src={require("../../../common/images/Oracle.png")}
                  alt=""
                />
              </span>
              <p>Oracle</p>
            </a>
          </li>
          <li className="col-12 col-md-3">
            <a>
              <div className="coming-soon">Coming Soon</div>
              <span>
                <img
                  src={require("../../../common/images/Workday.png")}
                  alt=""
                />
              </span>
              <p>Workday</p>
            </a>
          </li>
          <li className="col-12 col-md-3">
            <a>
              <div className="coming-soon">Coming Soon</div>
              <span>
                <img src={require("../../../common/images/SAP.png")} alt="" />
              </span>
              <p>SAP</p>
            </a>
          </li>
          <li className="col-12 col-md-3">
            <a>
              <div className="coming-soon">Coming Soon</div>
              <span>
                <img
                  src={require("../../../common/images/Zendesk.png")}
                  alt=""
                />
              </span>
              <p>Zendesk</p>
            </a>
          </li>
          <li className="col-12 col-md-3">
            <a>
              <div className="coming-soon">Coming Soon</div>
              <span>
                <img
                  src={require("../../../common/images/SharePoint.png")}
                  alt=""
                />
              </span>
              <p>SharePoint</p>
            </a>
          </li>
          <li className="col-12 col-md-3">
            <a>
              <div className="coming-soon">Coming Soon</div>
              <span>
                <img src={require("../../../common/images/HROne.jpg")} alt="" />
              </span>
              <p>HROne</p>
            </a>
          </li>
          <li className="col-12 col-md-3">
            <a>
              <div className="coming-soon">Coming Soon</div>
              <span>
                <img src={require("../../../common/images/ADP.png")} alt="" />
              </span>
              <p>ADP</p>
            </a>
          </li>
          <li className="col-12 col-md-3">
            <a>
              <div className="coming-soon">Coming Soon</div>
              <span>
                <img src={require("../../../common/images/Teams.png")} alt="" />
              </span>
              <p>Teams </p>
            </a>
          </li>
        </ul>
      </div>

      {/* <ThreeDotLoader visibility={showLoader}></ThreeDotLoader> */}
    </>
  );
};
export default Integrations;
